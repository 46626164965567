
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { BlogPost } from "@/types";
import { supabase } from "@/integrations/supabase/client";
import { Card, CardContent } from "@/components/ui/card";

interface RelatedArticlesProps {
  currentArticleId: string;
  categoryId?: number;
}

const RelatedArticles = ({ currentArticleId, categoryId }: RelatedArticlesProps) => {
  const [articles, setArticles] = useState<BlogPost[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchRelatedArticles = async () => {
      setIsLoading(true);
      console.log("Fetching related articles for article ID:", currentArticleId);
      
      try {
        // First try to fetch articles from the same category
        let query = supabase
          .from("blog_posts")
          .select("id, title, slug, featured_image")
          .eq("status", "published")
          .neq("id", currentArticleId)
          .order("published_on", { ascending: false })
          .limit(3);
        
        // If we have a category, filter by it first
        if (categoryId) {
          query = query.eq("category_id", categoryId);
        }
        
        let { data, error } = await query;
        
        console.log("Category-based related articles:", data);
        
        // If we don't have enough articles from the same category, fetch more articles
        if (!error && (!data || data.length < 3)) {
          const remainingCount = 3 - (data?.length || 0);
          
          // Get more articles (not from the same category and not the current article)
          const additionalQuery = supabase
            .from("blog_posts")
            .select("id, title, slug, featured_image")
            .eq("status", "published")
            .neq("id", currentArticleId)
            .order("published_on", { ascending: false })
            .limit(remainingCount);
            
          // If we have category results, exclude those categories too
          if (categoryId && data && data.length > 0) {
            additionalQuery.neq("category_id", categoryId);
          }
          
          const { data: additionalData, error: additionalError } = await additionalQuery;
          
          console.log("Additional related articles:", additionalData);
          
          if (!additionalError && additionalData) {
            // Combine the results
            data = [...(data || []), ...(additionalData || [])];
          }
        }
        
        setArticles(data || []);
      } catch (error) {
        console.error("Error fetching related articles:", error);
        setArticles([]);
      } finally {
        setIsLoading(false);
      }
    };

    if (currentArticleId) {
      fetchRelatedArticles();
    }
  }, [currentArticleId, categoryId]);

  if (isLoading) {
    return (
      <div className="my-8">
        <h2 className="text-2xl font-serif mb-6">Other Articles</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {[1, 2, 3].map((i) => (
            <Card key={i} className="animate-pulse">
              <div className="bg-accent h-40 rounded-t-lg"></div>
              <CardContent className="p-4">
                <div className="bg-accent h-5 w-3/4 rounded mb-2"></div>
                <div className="bg-accent h-4 w-1/2 rounded"></div>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    );
  }

  if (!articles.length) {
    return null;
  }

  return (
    <div className="my-12">
      <h2 className="text-2xl font-serif mb-6 text-primary text-left">Other Articles</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {articles.map((article) => (
          <Link
            key={article.id}
            to={`/articles/${article.slug}`}
            className="group"
          >
            <Card className="overflow-hidden h-full hover:shadow-md transition-shadow">
              {article.featured_image && (
                <div className="aspect-[16/9] overflow-hidden">
                  <img
                    src={article.featured_image}
                    alt={article.title}
                    className="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                  />
                </div>
              )}
              <CardContent className="p-4">
                <h3 className="text-lg font-medium line-clamp-2 group-hover:text-primary transition-colors">
                  {article.title}
                </h3>
              </CardContent>
            </Card>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default RelatedArticles;
