
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer";
import { format } from "date-fns";
import ContentRenderer from "@/components/articles/ContentRenderer";
import RelatedArticles from "@/components/articles/RelatedArticles";
import NewsletterSection from "@/components/NewsletterSection";

const calculateReadingTime = (content: string): number => {
  const wordsPerMinute = 200;
  const wordCount = content.trim().split(/\s+/).length;
  return Math.ceil(wordCount / wordsPerMinute);
};

const Article = () => {
  const { slug } = useParams();

  const { data: article, isLoading } = useQuery({
    queryKey: ["article", slug],
    queryFn: async () => {
      console.log("Fetching article with slug:", slug);
      const { data, error } = await supabase
        .from("blog_posts")
        .select("*")
        .eq("slug", slug)
        .eq("status", "published")
        .maybeSingle();

      if (error) {
        console.error("Error fetching article:", error);
        throw error;
      }
      console.log("Article data:", data);
      return data;
    },
  });

  useEffect(() => {
    document.title = article ? `${article.title} | Cody McLain` : "Loading Article | Cody McLain";
  }, [article]);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-background">
        <Navigation />
        <div className="animate-pulse pt-24">
          <div className="container mx-auto px-6 max-w-3xl">
            <div className="h-12 bg-accent rounded w-3/4 mb-4" />
            <div className="h-6 bg-accent rounded w-1/4 mb-8" />
            <div className="space-y-4">
              <div className="h-4 bg-accent rounded w-full" />
              <div className="h-4 bg-accent rounded w-full" />
              <div className="h-4 bg-accent rounded w-3/4" />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }

  if (!article) {
    return (
      <div className="min-h-screen bg-background">
        <Navigation />
        <div className="container mx-auto px-6 pt-32 text-left">
          <h1 className="text-4xl font-sans mb-4">Article Not Found</h1>
          <p className="text-primary-muted">
            The article you're looking for doesn't exist or has been removed.
          </p>
        </div>
        <Footer />
      </div>
    );
  }

  const readingTime = calculateReadingTime(article.content);

  return (
    <div className="min-h-screen bg-background flex flex-col">
      <Navigation />
      
      <main className="flex-grow pt-24">
        <article className="container mx-auto px-6 max-w-3xl">
          <header className="mb-16">
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-serif leading-tight mb-6 text-left">
              {article.title}
            </h1>
            <div className="flex items-center gap-4 text-primary-muted mb-8">
              <time className="text-sm">
                {format(new Date(article.published_on), "MMM d, yyyy")}
              </time>
              <span className="text-sm">·</span>
              <span className="text-sm">{readingTime} min read</span>
            </div>
            {article.featured_image && (
              <div className="aspect-[16/9] mb-12">
                <img
                  src={article.featured_image}
                  alt={article.title}
                  className="w-full h-full object-cover rounded-lg"
                />
                {article.image_attribution && (
                  <p className="text-sm text-primary-muted mt-2 text-left">
                    {article.image_attribution}
                  </p>
                )}
              </div>
            )}
          </header>

          <ContentRenderer 
            content={article.content} 
            format={article.content_format as 'html' | 'editorjs'} 
          />
        </article>
        
        <div className="container mx-auto px-6 max-w-5xl">
          {/* Related Articles Section */}
          <div className="my-16 pt-12 border-t border-accent">
            <RelatedArticles currentArticleId={article.id} categoryId={article.category_id} />
          </div>
        </div>
          
        {/* Newsletter Section - Full width without any extra margins */}
        <div className="w-full bg-warm-light py-12 mt-12">
          <div className="container mx-auto px-6">
            <div className="max-w-2xl mx-auto">
              <h2 className="text-2xl font-serif mb-3 text-primary text-center">
                Subscribe to My Newsletter
              </h2>
              <p className="text-center text-primary-muted mb-6">
                Enjoyed this article? Sign up to receive more insights directly in your inbox.
              </p>
              <NewsletterSection />
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default Article;
